import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';

import ReactInfiniteScroller from 'react-infinite-scroller';

const DEFAULT_PAGE_START = 1;
const DEFAULT_LOADING_TEXT = 'Loading ...';
const DEFAULT_THRESHOLD = 100;

export const InfiniteScroller = ({
  children,
  pageStart = DEFAULT_PAGE_START,
  loadMore,
  loading,
  loadingText = DEFAULT_LOADING_TEXT,
  initialLoad,
  hasMore,
  showInfinite = true,
  threshold = DEFAULT_THRESHOLD,
  useWindow = false,
  parentRef,
}) => {
  const showScroller = showInfinite && Boolean(loadMore);

  const loader = (
    <div className="loader" key="loader">
      {loadingText}
    </div>
  );

  return showScroller ? (
    <ReactInfiniteScroller
      pageStart={pageStart}
      loadMore={loadMore}
      hasMore={hasMore}
      initialLoad={initialLoad}
      useWindow={useWindow}
      threshold={threshold}
      getScrollParent={() => parentRef?.current}
    >
      {loading ? [...children, loader] : children}
    </ReactInfiniteScroller>
  ) : (
    children
  );
};

InfiniteScroller.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  pageStart: PropTypes.number,
  loadMore: PropTypes.func,
  loading: PropTypes.bool,
  initialLoad: PropTypes.bool,
  hasMore: PropTypes.bool,
  showInfinite: PropTypes.bool,
  threshold: PropTypes.number,
  useWindow: PropTypes.bool,
  parentRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({current: PropTypes.any})]),
};

export default InfiniteScroller;
