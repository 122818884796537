import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {join, times, isEmpty} from 'lodash';

import InfiniteScroller from './infinite-scroller';

export const DropdownFooter = ({footerItems}) => {
  return (
    <li className="dropdown-footer">
      <ul>{footerItems}</ul>
    </li>
  );
};

export const AccordionFooter = ({footerItems, openText, closeText}) => {
  const [showFooter, setShowFooter] = useState(false);

  const toggleFooter = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowFooter(!showFooter);
  };

  const btnText = showFooter ? closeText : openText;

  return (
    Boolean(footerItems) && (
      <div className="accordion-footer">
        {showFooter && <DropdownFooter footerItems={footerItems} />}
        <div className="accordion-btn" onClick={toggleFooter}>
          {btnText}
        </div>
      </div>
    )
  );
};

export const DropdownButton = ({children, footer, title, open, scrollProps = {}, ...props}) => {
  const scrollRef = useRef(null);

  const openClassName = open ? 'open' : '';

  return (
    <div className={join(['dropdown', 'btn-group', openClassName], ' ')}>
      <button role="button" aria-haspopup="true" aria-expanded={open} type="button" className="nav-dropdown dropdown-toggle btn btn-default">
        {title}
      </button>
      <ul role="menu" className="dropdown-menu">
        <div className="dropdown-scroller" ref={scrollRef}>
          <InfiniteScroller parentRef={scrollRef} {...scrollProps}>
            {children}
          </InfiniteScroller>
        </div>
        {footer}
      </ul>
    </div>
  );
};

DropdownButton.propTypes = {
  children: PropTypes.array.isRequired,
  title: PropTypes.node,
  open: PropTypes.bool,
  scrollProps: PropTypes.object,
};

export const Dropdown = ({items, footerItems, accordionFooter, openText, closeText, bossMode, id, ...otherProps}) => {
  const [open, setOpen] = useState(false);

  const handleToggle = (e) => {
    const newOpen = !open;
    setOpen(newOpen);
    otherProps.onToggle && otherProps.onToggle(newOpen);
  };

  let footer;
  if (accordionFooter) {
    footer = <AccordionFooter footerItems={footerItems} openText={openText} closeText={closeText} />;
  } else if (footerItems) {
    footer = <DropdownFooter footerItems={footerItems} />;
  }

  return (
    <div className={bossMode && 'bossMode'} id={id}>
      <div className="btn-group" onClick={handleToggle}>
        {open && <div className="behind-dropdown" />}
        <DropdownButton open={open} footer={footer} {...otherProps}>
          {items}
        </DropdownButton>
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  title: PropTypes.node,
  id: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.node),
  footerItems: PropTypes.arrayOf(PropTypes.node),
};

export default Dropdown;
